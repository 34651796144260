import { groupBy } from "lodash";

export const calculateScores = async (data, factors) => {

  return new Promise((resolve, reject) => {
    
    const sumOfFactorScores = factors.reduce((a, b) => {
      return a + b.weight;
    }, 0);
  
    let weightedFactors = {};
  
    factors.forEach(factor => {
      weightedFactors[factor.key] = factor.weight / sumOfFactorScores;
    });
  
    const keys = Object.keys(weightedFactors);


    const scoredFactors = [];

    // loop over each features (30,000 ish polygons);
  
    for(let i = data.features.length - 1; i>=0; i--){
  
      // grab the single feature;

      let feat = {...data.features[i]};


      // initialize the score to zero;
      let score = 0;
  
      // for each  (e.g. "elevation")
      keys.forEach(key => {

  
        // check if the value is null. if it is skip it
        if(feat.properties[key] === null) return;

        // grab the normalized score
        let normScore = feat.properties[`norm-${key}`];

        // if the normalized score is non-numberical exit
        if(isNaN(normScore)) return;

        // otherwise, multiply the the normalized score by the factors weight and add the the running score
        return score += normScore * weightedFactors[key];
      });
  
      feat.properties.score = score;
  
      scoredFactors.push(feat);
  
    }

    // resolve the data with new features array that has updated scores
    resolve({...data, features: scoredFactors});
  })


}

const betweenColors = (a, b, amount) => {
  var ah = parseInt(a.replace(/#/g, ''), 16),

  ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
  bh = parseInt(b.replace(/#/g, ''), 16),
  br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
  rr = ar + amount * (br - ar),
  rg = ag + amount * (bg - ag),
  rb = ab + amount * (bb - ab);

   return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
}


export const interpolateColor = (scale, amount) => {

  let nums = scale.filter(el => !isNaN(el));
  let hexs = scale.filter(el => isNaN(el));

  let col = hexs[0];

  if(amount >= nums[nums.length - 1]){
    col = hexs[hexs.length -1];
    return col;
  }

  for(let i = 0; i < nums.length; i++){
    // if 0 we are the worst possible color (at zero index)
    if(amount === 0){
      col = hexs[0];
      break;
    }
    let startNum = nums[i - 1] || 0;
    let num = nums[i];
    let colorA = hexs[i - 1];
    let colorB = hexs[i];
    let gap = num - startNum;
    let distance = amount - startNum;
    let percentBetween = distance / gap;
    if(amount < num){
      col = betweenColors(colorA, colorB, percentBetween);
      break;
    }
  }

  return col;

   
}

export const normalize = (val, max, min, direction='higher') => {
  let v = (val - min) / (max - min);
  if(direction === 'higher'){
    return v;
  } else {
    return 1 - v;
  }
};

export const getWeightedPercentageFromRaw = (weights) => {
  const grouped = groupBy(flatten(weights), 'parent');
  let hashTable = {};
  Object.keys(grouped).forEach(key => {
    let sum = 0;
    grouped[key].forEach(cat => sum += cat.weight);
    hashTable[key] = sum;
  });
  return hashTable;
}


export const caluclateFeatureUtilityScore = (features=[], weightingPercent, objectives) => {

  // console.log({features, weightingPercent, objectives});

  const minMaxLookup = resolveMinMaxLookup({weighting: flatten(objectives), features});

  // console.log("here: ", minMaxLookup);

  return features.map(polygon => {
    const { properties } = polygon;
    // const { SITE } = properties;
    let utility_index = 0;
    let utils = {};
    let x = [];
    let y = [];
    let colors = [];

    const grouped = groupBy(flatten(objectives), 'parent');

    grouped['root'].forEach(objective => {
      let calcWeight = objective.weight / weightingPercent[objective.parent];
      // console.log({obj: objective.name, calcWeight});
      if(grouped[objective.key]){
        x.push(objective.name);
        colors.push(objective.color);
        // console.log(objective.children);
        let sumOfChildren = 0;
        objective.children.forEach(child => {
          calcWeight = child.weight / weightingPercent[child.parent];
          let normalizedVal = normalize(parseFloat(properties[child.key]), minMaxLookup[child.key].max, minMaxLookup[child.key].min, child.direction || 'higher');
          if(isNaN(normalizedVal)) normalizedVal = 0;
          sumOfChildren += (normalizedVal * calcWeight);
          utils[`${child.key}-utility`] = normalizedVal * calcWeight;
        });

        calcWeight = objective.weight / weightingPercent[objective.parent];

        utility_index += (sumOfChildren * calcWeight);
        utils[`${objective.key}-utility`] = sumOfChildren * calcWeight;
        y.push(sumOfChildren * calcWeight);
      } else {
        x.push(objective.name);
        colors.push(objective.color);
        let normalizedVal = normalize(parseFloat(properties[objective.key]), minMaxLookup[objective.key].max, minMaxLookup[objective.key].min, objective.direction || 'higher');
        if(isNaN(normalizedVal)) normalizedVal = 0;
        utility_index += (normalizedVal * calcWeight);
        utils[`${objective.key}-utility`] = normalizedVal * calcWeight;
        y.push(normalizedVal * calcWeight);
      }
    })

    // const { properties } = polygon;
    // let utility_index = 0;
    // let utils = {};
    // let x = [];
    // let y = [];
    // let colors = [];
    // objectives.forEach(cat => {
    //   if(cat.type === 'input'){
    //     // console.log('calc for input type');
    //     let normalizedVal = normalize(parseFloat(properties[cat.key]), parseFloat(cat.max), parseFloat(cat.min));
    //     if(isNaN(normalizedVal)) normalizedVal = 0;
    //     utility_index += (normalizedVal * weightedPercentage[cat.key]);
    //     utils[`${cat.key}-utility`] = normalizedVal * weightedPercentage[cat.key];
    //   } else {
    //     let scaleFound = cat.scale.find(el => el.rating === properties[cat.key]);
    //     if(scaleFound){
    //       if(cat.checked){
    //         x.push(cat.name);
    //         y.push(scaleFound.norm * weightedPercentage[cat.key]);
    //         colors.push(cat.color);
    //         utility_index += (scaleFound.norm * weightedPercentage[cat.key]);
    //       }
    //       utils[`${cat.key}-utility`] = scaleFound.norm * weightedPercentage[cat.key];
    //     }
    //   }
    // })
    return {
      ...polygon,
      properties: {
        ...properties,
        utility_index: utility_index,
        ...utils,
        x,
        y,
        colors
      }
    }
  })
}

export const flatten = (arr) => {
  let result = [];

  // Helper function to process each object
  function processObject(obj) {
      // Add the current object to the result array
      result.push(obj);

      // If the object has a 'children' property, recursively process each child
      if (obj.children && obj.children.length) {
          obj.children.forEach(child => processObject(child));
      }
  }

  // Start processing from the root objects
  arr.forEach(obj => processObject(obj));

  return result;
}

export const updateObjectByKeyImmutable = (objects, key, value, newData) => {
  return objects.map(obj => {
      if (obj[key] === value) {
          // Create a new object with updated data
          return { ...obj, ...newData };
      }

      if (obj.children) {
          // Recursively update children and create a new object with updated children
          return { ...obj, children: updateObjectByKeyImmutable(obj.children, key, value, newData) };
      }

      // Return the original object if no updates are necessary
      return obj;
  });
}

export const resolveMinMaxLookup = ({weighting=[], features=[]}) => {
  // console.log({weighting, features});
  const flatWeights = flatten(weighting);
  let hash = {};
  flatWeights.forEach(cat => {
    if(cat.children && cat.children.length > 0) return;
    const { key, min, max } = cat;

    if(min && max){
      hash[key] = {min, max};
      return;
    }

    let allValues = features.map(feat => {
      return(parseFloat(feat.properties[key]));
    }).filter(val => !isNaN(val));
    const minCalc = Math.min(...allValues);
    const maxCalc = Math.max(...allValues);
    hash[key] = {min: minCalc, max: maxCalc};
  });
  return hash;
  // console.log(hash);
}