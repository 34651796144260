import { interpolateRdYlGn } from "d3-scale-chromatic"

const FISH_IBI = [
  {
    rating: "Degraded",
    score: 1
  },
  {
    rating: "Moderately Degraded",
    score: 2
  },
  {
    rating: "Moderately Impacted",
    score: 3
  },
  {
    rating: "Mildly Impacted",
    score: 4
  },
  {
    rating: "Reference Quality",
    score: 5
  }
]

const INVERTIBRATE_IBI = [
  {
    rating: "Extremely Degraded",
    score: 1
  },
  {
    rating: "Degraded",
    score: 2
  },
  {
    rating: "Moderately Degraded",
    score: 3
  },
  {
    rating: "Moderately Impacted",
    score: 4
  },
  {
    rating: "Mildly Impacted",
    score: 5
  },
  {
    rating: "Reference Conditions",
    score: 6
  }
]

const VEGITATION_IBI = [
  {
    rating: "Extremely Degraded",
    score: 0
  },
  {
    rating: "Degraded",
    score: 0.167
  },
  {
    rating: "Moderately Degraded",
    score: 0.333
  },
  {
    rating: "Moderately Impacted",
    score: 0.5
  },
  {
    rating: "Mildly Impacted",
    score: 0.667
  },
  {
    rating: "Reference Conditions",
    score: 0.833
  }
]

const WQLU_INDEX = [
  {
    rating: "Degraded",
    score: 0
  },
  {
    rating: "Moderately Degraded",
    score: 20
  },
  {
    rating: "Moderately Impacted",
    score: 40
  },
  {
    rating: "Mildly Impacted",
    score: 60
  },
  {
    rating: "Reference Conditions",
    score: 80
  }
]

const AMPHIBIAN_IEC = [
  {
    rating: "Degraded",
    score: 0
  },
  {
    rating: "Moderately Degraded",
    score: 2
  },
  {
    rating: "Mildly Impacted",
    score: 4
  },
  {
    rating: "High Quality",
    score: 6
  },
  {
    rating: "Reference",
    score: 8
  }
]

const BIRD_IEC = [
  {
    rating: "Degraded",
    score: 0
  },
  {
    rating: "Moderately Degraded",
    score: 2
  },
  {
    rating: "Mildly Impacted",
    score: 3.5
  },
  {
    rating: "High Quality",
    score: 6
  },
  {
    rating: "Reference",
    score: 7
  }
];

const SITES = [
  736,
  786,
  790,
  791,
  792,
  793,
  795,
  796,
  799,
  802,
  804,
  805,
  806,
  807,
  808,
  809,
  810,
  811,
  812,
  813,
  814,
  815,
  816,
  817,
  820,
  821,
  833,
  834,
  857,
  862,
  867,
  868,
  869,
  869,
  873,
  878,
  882,
  890,
  900,
  901,
  903,
  906,
  908,
  910,
  913,
  915,
  917,
  918,
  920,
  922,
  923,
  926,
  7036,
  7055,
];

const INTERPOLATED_COLOR_ARRAY = [];
for(let i = 0; i <= 1; i = i + 0.01){
  INTERPOLATED_COLOR_ARRAY.push(interpolateRdYlGn(i));
}

export {SITES, VEGITATION_IBI, BIRD_IEC, INVERTIBRATE_IBI, FISH_IBI, AMPHIBIAN_IEC, WQLU_INDEX, INTERPOLATED_COLOR_ARRAY};