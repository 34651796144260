import { Depths, FontIcon, PrimaryButton, Text, getTheme } from "@fluentui/react";
import { Link } from 'react-router-dom';
import React from "react";
import { myTheme } from ".";
import topo from './assets/topo.png';

const Landing = (props) => {
  return <div>
    <section id="section-1" style={{height: '100vh', display: 'flex'}}>
      <div style={{flex: 1, background: myTheme.palette.themeLight, display: 'flex', alignItems: 'center'}}>
        <div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <FontIcon iconName="Globe2" style={{color: "#fff", margin: 48, fontSize: 120, textShadow: '-2px 2px 5px #666'}} />
            <Text variant="mega" styles={{root: {color: '#fff', textShadow: '-2px 2px 5px #666'}}}>Terraviz</Text>
          </div>
          <div style={{display: 'flex', gap: 32, marginLeft: 48}}>
            <Link to="/sign-up">
              <PrimaryButton text="Create An Account"></PrimaryButton>
            </Link>
            <Link to="/sign-in">
              <PrimaryButton text="Sign In"></PrimaryButton>
            </Link>
          </div>
        </div>
      </div>
      <div style={{flex: 1, background: getTheme().palette.neutralPrimaryAlt, position: 'relative'}}>
        <div style={{width: '100%', height: '100%', background: `url(${topo})`, backgroundSize: 400, opacity: 0.1, position: 'absolute'}}></div>
        <div style={{padding: 48}}>
          {/* <h1 style={{fontSize: 48, color: "#fff", fontWeight: 600}}>What is Terraviz?</h1>
          <p style={{color: '#fff', fontSize: 24}}>Connecting your data with the people who need it in an accessible way.</p>
          <p style={{color: '#fff', fontSize: 24}}>Data-driven styles and spacial analysis tools for better insights.</p>
          <p style={{color: '#fff', fontSize: 24}}>Share your insights with the world or specific groups.</p> */}
        </div>
      </div>
    </section>
    <section id="section-2" style={{height: '100vh'}}>
      <div style={{flex: 1, background: myTheme.palette.themeLight}}></div>
      <div style={{flex: 1, background: getTheme().palette.neutralPrimary}}></div>
    </section>
    <section id="section-3" style={{height: '100vh'}}>

    </section>

  </div>
}

export default Landing;