import { ActionButton, Checkbox, ChoiceGroup, ColorPicker, DefaultButton, Depths, Dropdown, IconButton, Label, PrimaryButton, TextField, getTheme } from '@fluentui/react';
import React from 'react';
import { myTheme } from '.';
import { Route, Switch, Redirect } from 'react-router-dom';
import { interpolateRdYlGn } from 'd3';
import { normalize } from './utilities';
import { CirclePicker  } from 'react-color';
import Papa from 'papaparse';


export default function Settings(props){

  const [categories, updateCategories] = React.useState([]);
  const [editCategory, setEditCategory] = React.useState(null);
  const [newCategory, setNewCategory] = React.useState(null);
  const [rawMergeData, setRawMergeData] = React.useState([]);
  const [mergeKey, setMergeKey] = React.useState(null);
  const [properties, setProperties] = React.useState([]);
  const [jsonData, setJsonData] = React.useState(null);
  const [weightingSetup, setWeightingSetup] = React.useState([
    {
      key: 'tree-1',
      id: 'asdfas',
      name: "Native Vegetation",
      parent: 'root',
      color: "#f44336",
      children: [
        {
          key: 'Native veg_norm',
          id: 'asd4gvfas',
          name: "Native vegetation index",
          parent: "tree-1"
        },
        {
          key: 'Interspersion',
          id: '400c0c0',
          name: "Structural Complexity",
          parent: "tree-1"
        }
      ]
    },
    {
      key: 'tree-2',
      id: '120d999c',
      name: "Wetland Connectivity",
      parent: 'root',
      color: "#e91e63",
      children: [
        {
          key: 'Hydrologic connectivity',
          id: '940c1',
          name: "Hydrological Connectivity",
          direction: 'lower',
          parent: 'tree-2'
        },
        {
          key: 'Functional connectivity Option 1',
          id: '940c12',
          name: "Functional Connectivity",
          parent: 'tree-2'
        },
        {
          key: "Wetland size NWI",
          id: '940c13',
          name: "Wetland Size",
          parent: 'tree-2'
        }
      ]
    },
    {
      key: 'Manoomin',
      id: '400c0c',
      name: "Manoomin",
      parent: 'root',
      color: '#9c27b0'
    },
    {
      key: 'Invasive species',
      id: 'c0c994',
      name: "Invasive species (lower)",
      direction: 'lower',
      parent: 'root',
      color: '#673ab7'
    },
    {
      key: 'Invasive species',
      id: 'z0990d',
      name: "Invasive species (higher)",
      direction: 'higher',
      parent: 'root',
      color: '#673ab7'
    },
    {
      key: 'Native animal',
      id: '04-9c99r',
      name: "Native animals",
      parent: 'root',
      color: "#2196f3"
    },
    {
      key: 'Bird community',
      id: '9d8x88x8',
      name: "Birds",
      parent: 'root',
      color: '#00bcd4'
    },
    {
      key: 'Water quality',
      id: '99x9x904',
      name: "Water quality (lower)",
      direction: 'higher',
      parent: 'root',
      color: "#4caf50"
    },
    {
      key: 'Water quality',
      id: '99x9x904434343',
      name: "Water quality (higher)",
      direction: 'higher',
      parent: 'root',
      color: "#4caf50"
    },
    {
      key: 'Ease of access',
      id: '04',
      name: "Harvest and Recreational Opportunities (lower)",
      direction: 'lower',
      parent: 'root',
      color: '#ffc107'
    },
    {
      key: 'Ease of access',
      id: '0499',
      name: "Harvest and Recreational Opportunities (higher)",
      direction: 'higher',
      parent: 'root',
      color: '#ffc107'
    }
  ]);

  React.useEffect(() => {
    updateCategories(props.categories);
  }, [props.categories]);

  const saveChanges = () => {
    const updatedCategories = props.categories.map(cat => {
      if(cat.key === editCategory.key){
        return {
          ...cat,
          ...editCategory,
          scale: editCategory.scale.map((s, i) => {
            let newNorm = normalize(parseFloat(s.score), parseFloat(editCategory.max), parseFloat(editCategory.min));
            return {
              ...s, 
              norm: newNorm, 
              color: interpolateRdYlGn(newNorm)
            }
          })
        }
      }
      return {
        ...cat, 
        scale: cat.scale.map((s, i) => {
          let sameNorm = normalize(parseFloat(s.score), parseFloat(cat.max), parseFloat(cat.min));
          return {
            ...s, 
            norm: sameNorm, 
            color: interpolateRdYlGn(sameNorm)
          }
        })
      }
    });
    props.setCategories(updatedCategories);
    props.saveData(props.data, updatedCategories, weightingSetup, true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (results) => {
          // console.log('Parsed CSV data:', results.data);
          setRawMergeData(results.data);
        },
        header: true, // Set to true if your CSV has column headers, or false if not
        skipEmptyLines: true, // Skips empty lines in the CSV file
        dynamicTyping: true, // Automatically converts strings to numbers or booleans based on their contents
      });
    }
  };

  const handleJSONFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result);
          setJsonData(json);
          alert('File loaded and parsed successfully!');
        } catch (error) {
          alert('Error parsing JSON!');
        }
      };
      reader.readAsText(file);
    }
  };

  const saveWeightingSetup = () => {
    props.saveData(props.data, props.categories, weightingSetup, true);
  }

  const addNewCategory = () => {

    const updatedCategories = props.categories.concat({
      ...newCategory,
      scale: newCategory.scale.map((s, i) => {
        let sameNorm = normalize(parseFloat(s.score), parseFloat(newCategory.max), parseFloat(newCategory.min));
        return {
          ...s,
          norm: sameNorm,
          color: interpolateRdYlGn(sameNorm)
        }
      })
    });

    props.setCategories(updatedCategories);
    props.saveData(props.data, updatedCategories, weightingSetup, true)
  };

  const keys = React.useMemo(() => {
    if(!rawMergeData.length) return null;
    return Object.keys(rawMergeData[0]);
  }, [rawMergeData]);

  const categoryKeys = React.useMemo(() => {
    let keys = [];
    const ignoreKeys = ['x', 'y', 'colors', 'open', 'actively_selected', 'SITE', 'NAME', 'utility_index', 'site', 'scores'];
    props.data && props.data.features && Object.keys(props.data.features[0].properties).forEach(key => {
      if(key.endsWith('-utility') || ignoreKeys.includes(key)) return;
      keys.push(key);
    });
    return keys;
  }, [props.data]);


  const startMerge = () => {
    // console.log({mergeTo: props.data, rawMergeData});

    const newData = {
      ...props.data,
      features: props.data.features.map(feat => {
        let mergeTemp = {};
        const dataToMerge = rawMergeData.find(el => el[mergeKey] === feat.properties[mergeKey]);
        if(dataToMerge){
          properties.forEach(key => {
            mergeTemp[key] = dataToMerge[key];
          })
        };

        return {
          ...feat,
          properties: {
            ...feat.properties,
            ...mergeTemp
          }
        }
      })
    }

    // console.log(newData);

    props.saveData(newData, props.categories, weightingSetup, true);

    // console.log(newData);
  }

  const findMaxByKey = (key) => {
    let max = Math.max(...props.data.features.map(feat => parseFloat(feat.properties[key])).filter(val => !isNaN(val)));
    // console.log(max);
    return max;
  }

  const findMinByKey = (key) => {
    let min = Math.min(...props.data.features.map(feat => parseFloat(feat.properties[key])).filter(val => !isNaN(val)));
    return min;
  }

  // console.log({jsonData});

  const unmergedSites = React.useMemo(() => {
    if(!props.data || !jsonData) return null;
    let unmerged = [];
    const allExistingSites = props.data.features.map(feat => feat.properties.SITE);
    // console.log(allExistingSites);
    jsonData.features.forEach(feature => {
      const exists = allExistingSites.includes(feature.properties.SITE);
      if(exists){
        console.log("Already have ", feature.properties.NAME);
      } else {
        console.log("Existing data is missing ", feature.properties.NAME);
        unmerged.push(feature);
      }
    });
    console.log(unmerged);
  }, [props.data, jsonData]);

  const deleteCategory = (cat) => {
    const confirmed = window.confirm("Are you sure you want to delete this category?");
    if(confirmed){
      setEditCategory(null);
      const updatedCategories = props.categories.filter(c => {
        return c.name !== cat.name;
      });
      props.setCategories(updatedCategories);
      props.saveData(props.data, updatedCategories, weightingSetup, true);
    }
  }

  const recursiveRender = (children) => {
    if(!children){
      return null;
    }
    return <ul>
      {
        children.map(child => {
          return <li key={child.key}>
            {child.name}
            {recursiveRender(child.children)}
          </li>
        })
      }
    </ul>
  }

  return <div style={{width: 600, boxShadow: Depths.depth64, zIndex: 1, paddingTop: 32}}>
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
      <ActionButton text="Categories" onClick={() => props.history.push(`${props.match.url}/categories`)} />
      <ActionButton text="Weighting Setup" onClick={() => props.history.push(`${props.match.url}/weighting`)} />
      <ActionButton text="Data Merge" onClick={() => props.history.push(`${props.match.url}/data`)} />
      <ActionButton text="Geo JSON Merge" onClick={() => props.history.push(`${props.match.url}/geojson`)} />
    </div>
    <Switch>
      <Route path={`${props.match.path}/weighting`} render={() => {
        return <div style={{paddingLeft: 8, paddingRight: 8}}>
          <h2>Weighting Setup</h2>
          <p>Develop a tree structure to dictate you swing weighting parameters.</p>
          {recursiveRender(weightingSetup)}
          <PrimaryButton text="Save Changes" onClick={saveWeightingSetup} />
        </div>
      }} />


      <Route path={`${props.match.path}/categories`} render={() => {
        return <div style={{paddingLeft: 8, paddingRight: 8}}>
          <h2>Category Settings</h2>   
          {
            newCategory ? (
              <div>
                <h3>Build New Category</h3>
                <div style={{display: 'flex', gap: 8, alignItems: 'flex-end'}}>
                  <Dropdown styles={{root: {flex: 1}}} label="Lookup Key" options={categoryKeys.map(cat => ({key: cat, text: cat}))} selectedKey={newCategory.key} onChange={(e, o) => setNewCategory(prev => ({...prev, key: o.key}))} />
                  <IconButton iconProps={{iconName: "Cancel"}} onClick={() => setNewCategory(prev => ({...prev, key: `key-${Date.now()}`}))} />
                </div>
                
                <p style={{background: myTheme.palette.themeLighterAlt, padding: 8, border: `1px solid ${myTheme.palette.themePrimary}`, borderRadius: 2}}>Leave blank to create a new property value for each area. Or, select an existing attribute.</p>
                <Dropdown 
                  label="Property Type" 
                  options={[{key: 'scale', text: "Constructed Scale"}, {key: 'input', text: "Numeric Input"}]} 
                  onChange={(e, o) => setNewCategory(prev => ({...prev, type: o.key}))} />
                <Dropdown 
                  label="Preferred Direction" 
                  options={[{key: 'lower', text: "Lower is better"}, {key: 'higher', text: "Higher is better"}]} 
                  onChange={(e, o) => setNewCategory(prev => ({...prev, direction: o.key}))} />
                <TextField 
                  label='Name' 
                  value={newCategory.name} 
                  onChange={(e, name) => setNewCategory(prev => ({...prev, name}))} />
                <TextField 
                  label='Min' 
                  value={newCategory.min} 
                  suffix={<IconButton iconProps={{iconName: "Refresh"}} onClick={() => setNewCategory(prev => ({...prev, min: findMinByKey(newCategory.key)}))} />}
                  onChange={(e, min) => setNewCategory(prev => ({...prev, min}))} />
                <TextField 
                  label='Max' 
                  value={newCategory.max}
                  suffix={<IconButton iconProps={{iconName: "Refresh"}} onClick={() => setNewCategory(prev => ({...prev, max: findMaxByKey(newCategory.key)}))} />}
                  onChange={(e, max) => setNewCategory(prev => ({...prev, max}))} />
                <Label>Color</Label>
                <CirclePicker   
                  color={newCategory.color}
                  onChange={(col) => setNewCategory(prev => ({...prev, color: col.hex}))} />
                
                <div style={{display: newCategory.type === 'scale' ? 'block' : 'none'}}>
                  <Label>Scale</Label>
                  <table style={{width: '100%', padding: 8, border: `1px solid ${myTheme.palette.themePrimary}`, borderRadius: 2, marginBottom: 16, background: getTheme().palette.neutralLight}}>
                    <thead>
                      <tr>
                        <td>Rating Text</td>
                        <td>Score</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        newCategory.scale.map((scale, i) => {
                          return <tr key={i}>
                            <td>
                              <TextField 
                                value={scale.rating}
                                onChange={(e, val) => setNewCategory(prev => ({...prev, scale: prev.scale.map((s, x) => {
                                if(i === x){
                                  return {
                                    ...s,
                                    rating: val
                                  }
                                }
                                return s;
                              })}))} />
                            </td>
                            <td>
                              <TextField value={scale.score} onChange={(e, val) => setNewCategory(prev => ({...prev, scale: prev.scale.map((s, x) => {
                                if(i === x){
                                  return {
                                    ...s,
                                    score: val
                                  }
                                }
                                return s;
                              })}))} />
                            </td>
                            <td><IconButton iconProps={{iconName: "Delete"}} onClick={() => setNewCategory(prev => ({...prev, scale: prev.scale.filter((el, x) => x !== i)}))} /></td>
                          </tr>
                        })
                      }
                      <tr>
                        <td colSpan={3}>
                          <ActionButton text="Add Rating" iconProps={{iconName: "AddTo"}} onClick={() => setNewCategory(prev => ({...prev, scale: prev.scale.concat({rating: "", score: 0})}))} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{display: 'flex', justifyContent: 'flex-end', gap: 12, marginTop: 16}}>
                  <DefaultButton onClick={() => setNewCategory(null)} text="Cancel" />
                  <PrimaryButton onClick={addNewCategory} text="Add New Category" />
                </div>
              </div>
            ) : editCategory ? (
              <div>
                <h3>
                  Edit Category
                  <IconButton iconProps={{iconName: "Delete"}} onClick={() => deleteCategory(editCategory)} />
                </h3>
                <div style={{display: 'flex', gap: 8, alignItems: 'flex-end'}}>
                  <Dropdown styles={{root: {flex: 1}}} label="Lookup Key" options={categoryKeys.map(cat => ({key: cat, text: cat}))} selectedKey={editCategory.key} onChange={(e, o) => setEditCategory(prev => ({...prev, key: o.key}))} />
                  <IconButton iconProps={{iconName: "Cancel"}} onClick={() => setEditCategory(prev => ({...prev, key: `key-${Date.now()}`}))} />
                </div>
                <Dropdown 
                  label="Property Type" 
                  selectedKey={editCategory.type}
                  options={[{key: 'scale', text: "Constructed Scale"}, {key: 'input', text: "Numeric Input"}]} 
                  onChange={(e, o) => setEditCategory(prev => ({...prev, type: o.key}))} />
                <Dropdown 
                  label="Preferred Direction" 
                  selectedKey={editCategory.direction}
                  options={[{key: 'higher', text: "Higher is better"}, {key: 'lower', text: "Lower is better"}]} 
                  onChange={(e, o) => setEditCategory(prev => ({...prev, direction: o.key}))} />
                <TextField 
                  label='Name' 
                  value={editCategory.name} 
                  onChange={(e, name) => setEditCategory(prev => ({...prev, name}))} />
                <TextField 
                  label='Min' 
                  suffix={<IconButton iconProps={{iconName: "Refresh"}} onClick={() => setEditCategory(prev => ({...prev, min: findMinByKey(editCategory.key)}))} />} 
                  value={editCategory.min} 
                  onChange={(e, min) => setEditCategory(prev => ({...prev, min}))} />
                <TextField 
                  label='Max'
                  suffix={<IconButton iconProps={{iconName: "Refresh"}} onClick={() => setEditCategory(prev => ({...prev, max: findMaxByKey(editCategory.key)}))} />} 
                  value={editCategory.max} 
                  onChange={(e, max) => setEditCategory(prev => ({...prev, max}))} />
                <Label>Color</Label>
                <CirclePicker   
                  color={editCategory.color}
                  onChange={(col) => setEditCategory(prev => ({...prev, color: col.hex}))} />
                <div style={{display: editCategory.type !== 'input' ? 'block' : 'none'}}>
                  <Label>Constructed Scale</Label>
                  <table style={{background: myTheme.palette.themeLighterAlt, width: '100%', padding: 8, border: `1px solid ${myTheme.palette.themePrimary}`, borderRadius: 2, marginBottom: 16}}>
                    <thead>
                      <tr>
                        <td>Rating Text</td>
                        <td>Score</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        editCategory.scale.map((scale, i) => {
                          return <tr key={i}>
                            <td>
                              <TextField 
                                value={scale.rating}
                                disabled
                                onChange={(e, val) => setEditCategory(prev => ({...prev, scale: prev.scale.map((s, x) => {
                                if(i === x){
                                  return {
                                    ...s,
                                    rating: val
                                  }
                                }
                                return s;
                              })}))} />
                            </td>
                            <td>
                              <TextField value={scale.score} onChange={(e, val) => setEditCategory(prev => ({...prev, scale: prev.scale.map((s, x) => {
                                if(i === x){
                                  return {
                                    ...s,
                                    score: val
                                  }
                                }
                                return s;
                              })}))} />
                            </td>
                            <td><IconButton iconProps={{iconName: "Delete"}} onClick={() => setEditCategory(prev => ({...prev, scale: prev.scale.filter((el, x) => x !== i)}))} /></td>
                          </tr>
                        })
                      }
                      <tr>
                        <td colSpan={3}>
                          <ActionButton text="Add Rating" iconProps={{iconName: "AddTo"}} onClick={() => setEditCategory(prev => ({...prev, scale: prev.scale.concat({rating: "", score: 0})}))} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{display: 'flex', justifyContent: 'flex-end', gap: 8, marginTop: 16}}>
                  <DefaultButton onClick={() => setEditCategory(null)} text="Cancel" />
                  <PrimaryButton onClick={saveChanges} text="Save Changes" />
                </div>
              </div>
            ) : (
              <div style={{paddingTop: 8, paddingBottom: 8, marginLeft: -8, marginRight: -8}}>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                  {
                    categories.map((cat, i) => {
                      return <div key={i} style={{width: 'calc(33.33% - 32px)', margin: 8, padding: 8, display: "flex", alignItems: 'center', background: '#fff', boxShadow: Depths.depth4}}>
                        <h4>{cat.name}</h4>
                        <IconButton iconProps={{iconName: "Edit"}} onClick={() => setEditCategory(cat)} />
                      </div>
                    })
                  }
                  <div  style={{width: 'calc(33.33% - 32px)', margin: 8, padding: 8, display: "flex", alignItems: 'center', background: myTheme.palette.themeLighter, cursor: 'pointer', boxShadow: Depths.depth4}}>
                    <h4>Add New Category</h4>
                    <IconButton iconProps={{iconName: "AddTo"}} onClick={() => setNewCategory({
                      checked: true, 
                      color: "#eaeaea", 
                      key: `scale-${Date.now()}`,
                      type: 'scale', 
                      direction: 'higher',
                      scale: [
                        {rating: "Scale 1", score: 1}, 
                        {rating: "Scale 2", score: 2}, 
                        {rating: "Scale 3", score: 3}, 
                        {rating: "Scale 4", score: 4}, 
                        {rating: "Scale 5", score: 5}
                      ], 
                      min: 1, 
                      max: 5, 
                      name: "New Category"
                    })} />
                  </div>
                </div>
              </div>
            )
          }
        </div>
      }}></Route>
      <Route path={`${props.match.path}/data`} render={() => {
        return <div style={{height: '100%', overflowY: 'scroll'}}>
          <h2>Merge New Data</h2>
          <input type="file" accept=".csv" onChange={handleFileChange} />
          {
            keys && <div>
              <h3>Select Merge Key</h3>
              <p>This should map to existing source data column in your database.</p>
              <ChoiceGroup 
                options={keys.map(k => ({key: k, text: k}))} 
                onChange={(e, o) => setMergeKey(o.key)} />
              {/* {
                keys.map(key => {
                  return <ChoiceGroup key={key} checked={key === mergeKey} onChange={(e, checked) => setMergeKey(checked)} label={key} />
                })
              } */}
            </div>
          }
          {
            mergeKey && keys && <div>
              <h3>Select Data to Merge</h3>
              <p>These properties will be merged with the existing data.</p>
              {
                keys.map(key => {
                  if(key === mergeKey) return null;
                  return <Checkbox styles={{root: {marginTop: 8}}} label={key} checked={properties.includes(key)} onChange={(e, checked) => setProperties(prev => {
                    if(!checked){
                      return prev.filter(el => el !== key)
                    } else {
                      return prev.concat(key);
                    }
                  })} />
                })
              }
              <p>
                <PrimaryButton text="Start Merge" onClick={startMerge} />
              </p>
            </div>
          }
        </div>
      }}>
      </Route>
      <Route path={`${props.match.path}/geojson`} render={(p) => {
        return <div style={{height: '100%', overflowY: 'scroll'}}>
          <h2>Merge Spatial Data</h2>
          <input type="file" onChange={handleJSONFileChange} accept=".json" />

        </div>
      }} />
      <Redirect to={props.match.path + "/categories"} />
    </Switch>
  </div>
}