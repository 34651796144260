import React from 'react';

import 'mapbox-gl/dist/mapbox-gl.css';

import Map, { Layer, Popup, Source } from 'react-map-gl';
import axios from 'axios';
import { bbox } from '@turf/turf';

import { group, interpolateRdYlGn, map } from 'd3';
import { myTheme } from './index';

import { INTERPOLATED_COLOR_ARRAY } from './scoring';
import { ActionButton, Checkbox, DefaultButton, Depths, Dialog, DialogFooter, Dropdown, FontIcon, IconButton, Label, Link, PrimaryButton, SearchBox, Slider, TextField, getTheme } from '@fluentui/react';
import { Route, Switch, NavLink, useLocation, useParams } from 'react-router-dom';
import Settings from './Settings';
import UserWeights from './UserWeights';

import { normalize, flatten, resolveMinMaxLookup, updateObjectByKeyImmutable } from './utilities';
import SurveyResponses from './SurveyResponses';
import { groupBy, round } from 'lodash';

const recursiveUpdate = arr => {
  return arr.map(val => {
    return {
      ...val,
      weight: 50,
      children: val.children ? recursiveUpdate(val.children) : null
    }
  })
}

const mapboxAccessToken = 'pk.eyJ1IjoiY29tcGFzc3JtIiwiYSI6ImNrenljeTYwZTBhM3Yyb3FlcGs5NGQxYTEifQ.18qzJK-H6DaetAS9fpUi7A';

const BASEMAPS = [
  {
    name: "Satellite", 
    url: "mapbox://styles/compassrm/clcjlfztu000614qq566nxmzv"
  }, 
  {
    name: "Monochrome", 
    url: "mapbox://styles/compassrm/cln3pcrs7005l01r7bj877fbv"
  }
];

export default function Terraviz(props){

  const { pathname } = useLocation();
  const { project_key } = useParams();

  const instructionStyle = {
    fontSize: 18, 
    background: myTheme.palette.themeLighterAlt, 
    padding: 8, 
    border: `1px solid ${myTheme.palette.themePrimary}`, 
    boxShadow: Depths.depth4, borderRadius: 4
  };

  const mapRef = React.useRef(null);
  const [searchFilter, setSearchFilter] = React.useState("");
  const [basemap, setBasemap] = React.useState(BASEMAPS[1].url);
  const [viewState, setViewState] = React.useState({
    "longitude": -84.17585767143115,
    "latitude": 46.25549319620754,
    "zoom": 9.361891532077541,
    "pitch": 0,
    "bearing": 0,
  });

  const [dataView, setDataView] = React.useState('list');

  React.useEffect(() => {
    mapRef.current && mapRef.current.resize();
    if(pathname.includes('survey') && mapRef){
      mapRef?.current?.panTo([viewState.longitude, viewState.latitude], {zoom: viewState.zoom});
    }
  }, [pathname, mapRef]);

  const [CATEGORIES, setCategories] = React.useState([]);

  const [popupDetails, setPopupDetails] = React.useState(null);

  const changeBaseMap = (url) => setBasemap(url);

  const [showFilters, toggleShowFilters] = React.useState(false);

  const [filters, setFilters] = React.useState({});

  const [weighting, setWeighting] = React.useState([]);

  const weightingPercent = React.useMemo(() => {
    const grouped = groupBy(flatten(weighting), 'parent');
    let sums = {};
    Object.keys(grouped).forEach(key => {
      let sum = 0;
      grouped[key].forEach(child => sum += child.weight);
      sums[key] = sum;
    })
    return sums;
  }, [weighting]);

  React.useEffect(() => {

    runUpdates();

  }, [weightingPercent]);

  const toggleCategoryFilter = (key, rating, checked) => {
    if(!checked){
      setFilters(prev => ({...prev, [key]: prev[key].filter(el => el !== rating)}));
    } else {
      setFilters(prev => ({...prev, [key]: prev[key].concat(rating)}))
    }
  }

  const [data, setData] = React.useState(null);
  const [minMaxLookup, setMinMaxLookup] = React.useState(null);

  const [dataLoaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    axios.get(`/api/project/${project_key}`)
      .then(response => {
        const { sources } = response.data.data;
        const lookup = resolveMinMaxLookup(sources[0]);
        setMinMaxLookup(lookup);
        setData(sources[0]);
        setCategories(sources[0].categories);
        setWeighting(recursiveUpdate(sources[0].weighting));
        let hashTable = {};
        sources[0].categories.forEach(cat => {
          hashTable[cat.key] = [];
        });
        setSelectedFormat(sources[0].categories[0].key)
        setFilters(hashTable);
        setLoaded(true);
      })
      .catch(err => {

      })
  }, [project_key]);

  const onMouseMove = (e) => {
    if(e.features[0]){
      setHoveredSite(e.features[0].properties['SITE']);
    } else {
      setHoveredSite(null)
    }
  }

  const onClickMap = (e) => {
    const { lngLat, features } = e;
    const {lng, lat} = lngLat;
    if(pathname.includes('survey')){
      if(features[0]){
        let checked = surveyData.selectedAreas.includes(features[0].properties.SITE);
        setSurveyData(prev => ({...prev, selectedAreas: checked ? prev.selectedAreas.filter(el => el !== features[0].properties.SITE) : prev.selectedAreas.concat(features[0].properties.SITE)}));
        // setPopupDetails({...features[0].properties, lng, lat});
      }
    } else {
      if(features[0]){
        setPopupDetails({...features[0].properties, lng, lat});
      } else {
        setPopupDetails(null);
      }
    }
  }

  const changePropertyById = (siteId, properties) => {
    setData(prev => ({...prev, features: prev.features.map(feat => {
      if(feat.properties["SITE"] === siteId){
        return {...feat, properties: {...feat.properties, ...properties}}
      }
      return feat;
    })}))
  }

  const zoomToFeature = (feature) => {
    const [minLng, minLat, maxLng, maxLat] = bbox(feature);

    mapRef.current.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat]
      ],
      {duration: 1200, padding: 50}
    );
  }

  const filterCount = React.useMemo(() => {
    let sum = 0;
    Object.keys(filters).forEach(key => {
      sum += filters[key].length;
    });
    return sum;
  }, [filters]);

  const [hoveredSite, setHoveredSite] = React.useState(null);

  const [selectedFormat, setSelectedFormat] = React.useState('utility');

  const lineProps = React.useMemo(() => {
    return {
      type: 'line',
      paint: {
        'line-color': [
          'case',
          ['==', ['get', 'SITE'], hoveredSite],
          // myTheme.palette.themePrimary, // show if picked
          'cyan',
          'white' // hide else
        ], 
        'line-width': [
          'case',
          ['==', ['get', 'SITE'], hoveredSite],
          2, // show if picked
          0 // hide else
        ]
      }
    }
  }, [hoveredSite]);

  const layerProps = React.useMemo(() => {

    if(CATEGORIES.length === 0) return;

    if(pathname.includes('survey')){
      return {
        id: 'data',
        type: 'fill',
        paint: {
          'fill-color': [
            'match',
            ['get', 'actively_selected'],
            'selected',
            myTheme.palette.themeSecondary,
            "",
            myTheme.palette.themeLight,
            myTheme.palette.themeLight
          ]
        }
      }
    }


    let arr = [];
    let obj = {};

    if(selectedFormat === 'utility'){
      return {
        id: 'data',
        type: 'fill',
        paint: {
          'fill-color': [
            'interpolate',
            ['linear'],
            ['get', 'utility_index'],
            0,
            interpolateRdYlGn(0),
            0.5,
            interpolateRdYlGn(0.5),
            1,
            interpolateRdYlGn(1),

          ],
          'fill-opacity': 1
        }
      }
    }

    let foundCategory = CATEGORIES.find(el => el.key === selectedFormat);

    if(foundCategory.type === 'input'){
      return {
        id: 'data',
        type: 'fill',
        paint: {
          'fill-color': [
            'interpolate',
            ['linear'],
            ['get', foundCategory.key],
            parseFloat(foundCategory.min),
            interpolateRdYlGn(foundCategory.direction === 'lower' ? 1 : 0),
            (parseFloat(foundCategory.max) + parseFloat(foundCategory.min)) / 2,
            interpolateRdYlGn(0.5),
            parseFloat(foundCategory.max),
            interpolateRdYlGn(foundCategory.direction === 'lower' ? 0 : 1),
          ],
          'fill-opacity': 1
        }
      }
    } else {

      let arr = [];

      foundCategory.scale.forEach(scale => arr.push(scale.rating, scale.color));
      return {
        id: 'data',
        type: 'fill',
        paint: {
          'fill-color': [
            'match',
            ['get', foundCategory.key],
            ...arr,
            "",
            getTheme().palette.neutralLight,
            getTheme().palette.neutralLight
          ],
          'fill-opacity': 1
        }
      }
    }

  }, [selectedFormat, CATEGORIES, pathname]);

  React.useEffect(() => {
    if(pathname.includes('charts')){
      setSelectedFormat('utility');
    }
  }, [pathname]);

  const filteredData = React.useMemo(() => {
    if(!filterCount || !data) return data;
    return {
      ...data,
      features: data.features.filter(feature => {
        let pass = true;
        CATEGORIES.forEach(cat => {
          if(filters[cat.key].length){
            if(!filters[cat.key].includes(feature.properties[cat.key])) pass = false;
          }
        });
        return pass;
      })
    }
  }, [filters, data, filterCount, CATEGORIES]);

  const [surveyData, setSurveyData] = React.useState({selectedAreas: [], when: [], what: [], comment: "", who: ""});

  const runUpdates = () => {
    // console.log(weightingPercent);
    setData(prev => {
      if(!prev) return prev;

      // console.log({weighting: flatten(weighting), weightingPercent});

      return {
        ...prev,
        features: prev.features.map(polygon => {
          const { properties } = polygon;
          const { SITE } = properties;
          let utility_index = 0;
          let utils = {};
          let x = [];
          let y = [];
          let colors = [];

          const grouped = groupBy(flatten(weighting), 'parent');

          grouped['root'].forEach(objective => {
            let calcWeight = objective.weight / weightingPercent[objective.parent];
            // console.log({obj: objective.name, calcWeight});
            if(grouped[objective.key]){
              x.push(objective.name);
              colors.push(objective.color);
              // console.log(objective.children);
              let sumOfChildren = 0;
              objective.children.forEach(child => {
                calcWeight = child.weight / weightingPercent[child.parent];
                let normalizedVal = normalize(parseFloat(properties[child.key]), minMaxLookup[child.key].max, minMaxLookup[child.key].min, child.direction || 'higher');
                if(isNaN(normalizedVal)) normalizedVal = 0;
                sumOfChildren += (normalizedVal * calcWeight);
                utils[`${child.key}-utility`] = normalizedVal * calcWeight;
              });

              calcWeight = objective.weight / weightingPercent[objective.parent];

              utility_index += (sumOfChildren * calcWeight);
              utils[`${objective.key}-utility`] = sumOfChildren * calcWeight;
              y.push(sumOfChildren * calcWeight);
            } else {
              x.push(objective.name);
              colors.push(objective.color);
              let normalizedVal = normalize(parseFloat(properties[objective.key]), minMaxLookup[objective.key].max, minMaxLookup[objective.key].min, objective.direction || 'higher');
              if(isNaN(normalizedVal)) normalizedVal = 0;
              utility_index += (normalizedVal * calcWeight);
              utils[`${objective.key}-utility`] = normalizedVal * calcWeight;
              y.push(normalizedVal * calcWeight);
            }
          })

          return {
            ...polygon,
            properties: {
              ...properties,
              utility_index: utility_index,
              ...utils,
              x,
              y,
              colors,
              'actively_selected': surveyData.selectedAreas.includes(SITE) ? "selected" : "not-selected"
            }
          }
        })
      }
    })
  };

  React.useEffect(() => {
    if(dataLoaded) runUpdates();
  }, [selectedFormat, CATEGORIES, surveyData, dataView, dataLoaded, weightingPercent, weighting, minMaxLookup]);

  const onClosePopup = () => {
    setPopupDetails(null)
  };

  const hoverSiteName = React.useMemo(() => {
    if(hoveredSite && dataView  === 'survey'){
      const found = data.features.find(el => el.properties.SITE === hoveredSite);
      return <div style={{position: "absolute", top: 16, right: 16, padding: '8px 16px', background: '#ffffff80'}}>
        <span style={{fontSize: 22, color: myTheme.palette.themePrimary, fontWeight: 'bold', textShadow: '0px 0px 20px #fff'}}>{found.properties.NAME}</span>
      </div>
    }
    return null;
  }, [hoveredSite, dataView]); 

  const popup = React.useMemo(() => {

    if(!popupDetails) return null;
    return <Popup
      key={popupDetails.SITE} 
      latitude={popupDetails.lat} 
      longitude={popupDetails.lng} 
      maxWidth='400px'
      closeOnClick={false}
      onClose={onClosePopup}>
      <h3>{popupDetails.NAME}</h3>
      <Properties CATEGORIES={CATEGORIES} {...popupDetails} />
    </Popup>
  }, [popupDetails, hoveredSite, dataView, surveyData]);


  const selectedAreas = React.useMemo(() => {
    if(!pathname.includes('survey') || surveyData.selectedAreas.length === 0) return [];
    return data.features.filter(el => surveyData.selectedAreas.includes(el.properties.SITE));
  }, [pathname, surveyData, data]);

  const [surveyStep, setSurveyStep] = React.useState(1);

  const [showSuccessMessage, toggleShowSuccessMessage] = React.useState(false);

  const [surveyFilter, setSurveyFilter] = React.useState("");

  const submitSurvey = () => {
    axios.post(`/api/project/${project_key}/survey`, surveyData)
    .then(res => {
      if(res.data.success){
        setSurveyData({comment: "", selectedAreas: [], what: [], when: [], who: "",});
        setSurveyStep(1);
        toggleShowSuccessMessage(true);
      } else {
        alert("Unable to save survey response");
      }
    })
  }

  const changeFeatureCategoyValue = (feature, option, key) => {
    setData(prev => {
      return {
        ...prev,
        features: prev.features.map(feat => {
          if(feat.properties.NAME === feature.properties.NAME){
            return {
              ...feat,
              properties: {
                ...feat.properties,
                [key]: option.key
              }
            }
          }
          return feat;
        })
      }
    })
    runUpdates();
  }


  const changeFeatureInputValue = (feature, value, key) => {
    setData(prev => {
      return {
        ...prev,
        features: prev.features.map(feat => {
          if(feat.properties.NAME === feature.properties.NAME){
            return {
              ...feat,
              properties: {
                ...feat.properties,
                [key]: value
              }
            }
          }
          return feat;
        })
      }
    })
    runUpdates();
  }

  const sortData = (key, isScale=false, abc=false, direction='higher') => {
    return setData(prev => {
      return {
        ...prev,
        features: prev.features.sort((a, b) => {
          if(isScale){
            let aValue = CATEGORIES.find(el => el.key === key)?.scale.find(el => el.rating === a.properties[key]);
            let bValue = CATEGORIES.find(el => el.key === key)?.scale.find(el => el.rating === b.properties[key]);
            if(!aValue && !bValue) return 0;
            if(!aValue) return 1;
            if(!bValue) return -1;
            return aValue.score < bValue.score ? 1 : aValue.score === bValue.score ? 0 : -1;
          }

          if(abc){
            return a.properties[key] <= b.properties[key] ? -1 : 1;
          }

          if(a.properties[key] === null || a.properties[key] === "") return 1;
          if(b.properties[key] === null || b.properties[key] === "") return -1;

          if(direction === 'lower'){
            return  a.properties[key] <= b.properties[key] ? -1 : 1;
          } else {
            return  a.properties[key] <= b.properties[key] ? 1 : -1;
          }
        })
      }
    })
  }

  const save = (updatedData, updatedCategories, weightingSetup, shouldSaveToDatabase) => {
    // update state
    setData(updatedData);
    setCategories(updatedCategories);

    // try updating database
    if(shouldSaveToDatabase){
      const confirm = window.prompt("Enter project pin to save changes to database. Otherwise, changes will only be kept within this session");
      if(confirm === "terraviz"){
        const saveData = {
          data: {
            sources: [{
              ...updatedData, 
              categories: updatedCategories || CATEGORIES,
              weighting: weightingSetup, 
              features: updatedData.features.map(feat => ({...feat, properties: {...feat.properties, 'actively_selected': 'not-selected', open: false}}))}],
            // categories: CATEGORIES
          }
        }
        axios.put(`/api/project/${project_key}`, saveData)
        .then(response => {
          console.log(response);
        })
        .catch(err => {
          console.log(err);
        })
      } else {
        window.alert("Changes saved locally. Refresh you browser to reset to global default settings.");
      }
    }

  }

  const selectedCategory = React.useMemo(() => {
    return CATEGORIES.find(cat => cat.key === selectedFormat)
  }, [selectedFormat, CATEGORIES]);


  const [name, setName] = React.useState("");

  const saveWeights = () => {
    axios.post(`/api/project/${project_key}/weights`, {name, weights: weighting})
    .then(res => {
      alert("Weights have been saved to database");
      setName('');
    })
    .catch(err => {
      console.log(err);
      setName('');
    })
  }

  const recursiveRenderer = (arr, depth=0, passColor, addBreak) => {
    return arr.map((item, i) => {
      // console.log(item);
      let color = passColor || item.color;
      return <React.Fragment key={i}>
        <tr style={{background: `${color}25`}}>
          <td style={{paddingLeft: `${8 + (depth * 16)}px`, borderLeft: `6px solid ${color}`, fontWeight: depth === 0 ? 600 : 400}}>{item.name}</td>
          <td style={{width: '50%'}}>
            <Slider 
              value={item.weight}
              showValue={false}
              min={0} 
              max={100} 
              onChange={(val) => setWeighting(prev => {
                return updateObjectByKeyImmutable(prev, 'id', item.id, {weight: val})
            })} />
          </td>
          <td syle={{width: 30}}>{item.weight}</td>
          <td syle={{width: 30}}>{round((item.weight / weightingPercent[item.parent]), 3)}</td>
        </tr>
        {
          item.children && item.children.length && recursiveRenderer(item.children, depth + 1, item.color, true)
        }
      </React.Fragment>
    })
  }


  return <div style={{height: '100vh', width: '100%', display: 'flex', flexDirection: pathname.includes('table') ? 'column-reverse' : 'row', position: 'relative'}}>
    <div style={{width: 32, background: myTheme.palette.themeLighter, position: 'absolute', top: 0, width: '100%', zIndex: 999, background: '#fff', height: 32, boxShadow: Depths.depth16, display: 'flex', justifyContent: 'flex-end'}}>
      <NavLink to={props.match.url + '/map'}><DefaultButton primary={pathname.includes('map')} styles={{root: {border: 'none !important'}, rootChecked: {border: 'none'}}} text="Map" /></NavLink>

      <DefaultButton 
        primary={pathname.includes('survey')} 
        styles={{root: {border: 'none !important'}, rootChecked: {border: 'none'}}} 
        text="Effort Mapping" 
        menuProps={{
          items: [
            {key: 'survey', text: "Create New", onClick: () => props.history.push(`${props.match.url}/survey`)}, 
            {key: "view", text: "View Responses", onClick: () => props.history.push(`${props.match.url}/survey-responses`)}
          ]
        }} />

      <NavLink to={props.match.url + '/table'}><DefaultButton primary={pathname.includes('table')} styles={{root: {border: 'none !important'}, rootChecked: {border: 'none'}}} text="Table" /></NavLink>
      <NavLink to={props.match.url + '/charts'}><DefaultButton primary={pathname.includes('charts')} styles={{root: {border: 'none !important'}, rootChecked: {border: 'none'}}} text="Utility Charts" /></NavLink>
      <NavLink to={props.match.url + '/user-weights'}><DefaultButton primary={pathname.includes('user-weights')} styles={{root: {border: 'none !important'}, rootChecked: {border: 'none'}}} text="User Weights" /></NavLink>
      <NavLink to={props.match.url + '/settings'}><DefaultButton primary={pathname.includes('settings')} styles={{root: {border: 'none !important'}, rootChecked: {border: 'none'}}} text="Settings" /></NavLink>
    </div>

    <Dialog modalProps={{isDarkOverlay: true}} hidden={!showSuccessMessage} dialogContentProps={{title: "Response Saved", titleProps: {style: {textAlign: 'center', padding: '16px 24px 20px 24px'}}}}  onDismiss={() => toggleShowSuccessMessage(false)}>
      <div style={{textAlign: 'center', paddingTop: 16, paddingBottom: 16}}>
        <FontIcon iconName="SkypeCircleCheck" style={{fontSize: 32, color: myTheme.palette.themePrimary}} />
      </div>
      <DialogFooter styles={{actionsRight: {textAlign: 'center'}}}>
        <DefaultButton text="Close" onClick={() => toggleShowSuccessMessage(false)} />
      </DialogFooter>
    </Dialog>

    <Switch>
      <Route path={`${props.match.path}/user-weights`} render={(p) => <UserWeights {...p} data={data} categories={CATEGORIES} />} />
      <Route 
        path={`${props.match.path}/survey`} render={(p) => {
        return <div style={{width: 400, padding: '32px 16px', boxShadow: Depths.depth64, zIndex: 1}}>
          <h2>Effort Mapping</h2>
          {
            surveyStep === 1 && (<>
              <p style={instructionStyle}>Please provide details about any past, current, and upcoming coastal wetland efforts of your organization that have occurred since 2010 within any wetland sites within the St. Marys River Structured Decision Making Project.</p>
              <PrimaryButton text="Get Started" onClick={() => setSurveyStep(2)} />
            </>
            )
          }
          {
            surveyStep === 2 && (<>
              <div style={instructionStyle}>
                <p style={{marginTop: 0}}>Please select one or more wetland sites with effort you want to report. The next questions will then ask you to categorize the effort on these wetlands by the time period (past, present, planned/future) and type (management - restoration, management - invasive species control, management - resilient infrastructure, research, monitoring).</p>
                <p style={{marginBottom: 0}}>You can select a single wetland and submit responses for individual efforts or select multiple wetlands that can be categorized the same way - e.g., your organization performed monitoring in the past at 5 sites, your organization is controlling invasive species at the present at 3 sites.</p>
              </div>
              <div style={{position: 'relative'}}>
                <Label>Search</Label>
                <SearchBox placeholder='Search available wetlands' id="search" value={searchFilter} onChange={(e, val) => setSearchFilter(val)} styles={{root: {marginBottom: 16}}} />

                <Label>Selected Wetlands</Label>
                <div style={{position: 'absolute', left: '100%', display: searchFilter ? 'flex' : 'none', flexDirection: 'column', boxShadow: `${Depths.depth16}`, zIndex: 99999, top: 0, background: "#fff", width: 'max-content'}}>
                  {
                    data.features.filter(el => el.properties.NAME.toLowerCase().includes(searchFilter.toLowerCase())).map((feat, i) => (
                      <ActionButton key={i} text={feat.properties.NAME} onClick={() => setSurveyData(prev => ({...prev, selectedAreas: prev.selectedAreas.includes(feat.properties.SITE) ? prev.selectedAreas.filter(el => el !== feat.properties.SITE) : prev.selectedAreas.concat(feat.properties.SITE)}))} iconProps={{iconName: surveyData.selectedAreas.includes(feat.properties.SITE) ? "BoxCheckmarkSolid" : "Checkbox"}} />
                    ))
                  }
                </div>
              </div>
              <div style={{marginBottom: 16}}>
                {
                    selectedAreas.length === 0 && <div style={{boxShadow: Depths.depth4, marginBottom: 4, display: 'flex', alignItems: 'center', border: `1px solid ${myTheme.palette.neutralTertiary}`, borderRadius: 2, background: getTheme().palette.neutralLight}}>
                    <div style={{height: 32, display: 'flex', alignItems: 'center', paddingLeft: 14}}>No areas selected</div>
                  </div>
                }
                {
                  selectedAreas.map((area, x) => {
                    return <div key={x} style={{boxShadow: Depths.depth4, marginBottom: 4, display: 'flex', alignItems: 'center', border: `1px solid ${myTheme.palette.themePrimary}`, borderRadius: 2}}>
                      <IconButton iconProps={{iconName: "Cancel"}} onClick={() => setSurveyData(prev => ({...prev, selectedAreas: prev.selectedAreas.filter(el => el !== area.properties.SITE)}))} />
                      <span>{area.properties.NAME}</span>
                    </div>
                  })
                }
              </div>
              <DefaultButton styles={{root: {marginRight: 8}}} text="Back" onClick={() => setSurveyStep(1)}/>
              <PrimaryButton disabled={surveyData.selectedAreas.length === 0} text="Next" onClick={() => setSurveyStep(3)} />
            </>)
          }
          {
            surveyStep === 3 && (<>
              <p style={instructionStyle}>When has/will the effort occurred at the selected wetlands?</p>
              {/* past effort/finished; ongoing/current; planned/future */}
              <div style={{marginTop: 16, marginBottom: 16}}>
                {
                  [
                    {key: "past effort / finished", text: "Past effort / Finished"},
                    {key: "ongoing / current", text: "Onoing / Current"},
                    {key: "planned / future", text: "Planned / Future"}
                  ].map(option => {
                    return <Checkbox 
                      key={option.key}
                      styles={{root: {marginBottom: 8}}}
                      checked={surveyData.when.includes(option.key)} 
                      label={option.text} 
                      onChange={(e, checked) => {
                      if(checked){
                        setSurveyData(prev => ({...prev, when: prev.when.concat(option.key)}));
                      } else {
                        setSurveyData(prev => ({...prev, when: prev.when.filter(el => el !== option.key)}))
                      }
                    }} />
                  })
                }
              </div>
              <DefaultButton styles={{root: {marginRight: 8}}} text="Back" onClick={() => setSurveyStep(2)}/>
              <PrimaryButton disabled={surveyData.when.length === 0} text="Next" onClick={() => setSurveyStep(4)} />
            </>)
          }
          {
            surveyStep === 4 && (<>
              <p style={instructionStyle}>What category best describes the effort?</p>

              <div style={{marginTop: 16, marginBottom: 16}}>
                {
                  [
                    {key: "management - restoration", text: "Management - Restoration"},
                    {key: "management - invasive species control", text: "Management - Invasive Species Control"},
                    {key: "management - resilient infrastructure", text: "Management - Resilient Infrastructure"},
                    {key: "research", text: "Research"},
                    {key: "monitoring", text: "Monitoring"}
                  ].map(option => {
                    return <Checkbox 
                      key={option.key}
                      styles={{root: {marginBottom: 8}}}
                      checked={surveyData.what.includes(option.key)} 
                      label={option.text} 
                      onChange={(e, checked) => {
                      if(checked){
                        setSurveyData(prev => ({...prev, what: prev.what.concat(option.key)}));
                      } else {
                        setSurveyData(prev => ({...prev, what: prev.what.filter(el => el !== option.key)}))
                      }
                    }} />
                  })
                }
              </div>
              <DefaultButton styles={{root: {marginRight: 8}}} text="Back" onClick={() => setSurveyStep(3)}/>
              <PrimaryButton disabled={surveyData.what.length === 0} text="Next" onClick={() => setSurveyStep(5)} />
            </>)
          }
          {
            surveyStep === 5 && (<>
              <p style={instructionStyle}>Please provide any other details about the effort.</p>
              <TextField 
                label='Name or Organization'
                styles={{root: {marginTop: 16, marginBottom: 16}}} 
                value={surveyData.who} 
                onChange={(e, val) => setSurveyData(prev => ({...prev, who: val}))} />
              <TextField 
                styles={{root: {marginTop: 16, marginBottom: 16}}} 
                multiline 
                label='Comments'
                rows={4} 
                value={surveyData.comment} 
                onChange={(e, val) => setSurveyData(prev => ({...prev, comment: val}))} />
              <DefaultButton styles={{root: {marginRight: 8}}} text="Back" onClick={() => setSurveyStep(4)}/>
              <PrimaryButton disabled={surveyData.what.length === 0} text="Submit" onClick={submitSurvey} />
            </>)
          }
        </div>
        }} />
      
      <Route path={`${props.match.path}/survey-responses`} render={(p) => <SurveyResponses {...p} data={data} categories={CATEGORIES} />}/>

      <Route path={`${props.match.path}/map`} render={(p) => {
        return <>
          <div style={{display: dataView === 'charts' || dataView === 'survey' || dataView === 'table' ? 'none' : 'block', position: 'absolute', top: 40, right: 16, width: 200, zIndex: 2, background: '#fff', padding: 16, boxShadow: Depths.depth64}}>
            <Dropdown 
              selectedKey={selectedFormat} 
              options={
                CATEGORIES.map(cat => ({key: cat.key, text: cat.name}))
                .concat({key: "utility", text: "Utility Index"})} 
              onChange={(e, o) => setSelectedFormat(o.key)} />
            {
              selectedFormat === "utility" ? <div>
                <Label>Utility Index</Label>
                <InterpolatedScale showHoveredValue={false} />
              </div> 
              : 
              <div>
                <Label>Legend</Label>
                {
                  selectedCategory?.type === 'input' ? <div>
                    <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: selectedCategory.direction === 'lower' ? 'row-reverse' : 'row'}}>
                      <span>{selectedCategory.min}</span>
                      <span>{selectedCategory.max}</span>
                    </div>
                    <InterpolatedScale showHoveredValue={false} />
                  </div> : <>{
                    selectedCategory?.scale.map(scale => {
                      return <div key={scale.rating}>
                        <div style={{marginBottom: 4, display: 'flex', alignItems: 'center'}}>
                          <div style={{height: 20, width: 20, background: scale.color, boxShadow: Depths.depth8, marginRight: 8, border: `1px solid ${getTheme().palette.neutralTertiary}`}}></div>
                          <span>{scale.rating}</span>
                        </div>
                      </div>
                    })
                  }
                  <div style={{marginBottom: 4, display: 'flex', alignItems: 'center'}}>
                    <div style={{height: 20, width: 20, background: getTheme().palette.neutralLight, boxShadow: Depths.depth8, marginRight: 8, border: `1px solid ${getTheme().palette.neutralTertiary}`}}></div>
                    <span>Not Available</span>
                  </div>
                  </>
                }
              </div>
            }
          </div>
          <div style={{width: 400, padding: '48px 0px 0px 0px', display: 'flex', flexDirection: 'column', boxShadow: Depths.depth64, background: '#fff', zIndex: 998}}>
            <div style={{display: 'flex', marginRight: 12, alignItems: 'center'}}>
              <SearchBox styles={{root: {flex: 1}}} value={searchFilter} onChange={(e, val) => setSearchFilter(val)} underlined placeholder='Search by Name' />
              <span style={{marginLeft: 12, marginRight: 4}}>{`(${filterCount})`}</span>
              <IconButton iconProps={{iconName: filterCount ? "FilterSolid" : "Filter"}} onClick={() => toggleShowFilters(prev => !prev)} />
            </div>
            <div style={{flex: 1, display: !showFilters ? 'none' : 'flex', flexWrap: 'wrap', gap: 16, marginLeft: 12}}>
              {
                CATEGORIES.map((cat, i) => {
                  return <div key={i}>
                    <h4>{cat.name}</h4>
                    {
                      cat.scale.map((scale, x) => {
                        return <Checkbox key={x} styles={{root: {marginBottom: 4}}} checked={filters[cat.key].includes(scale.rating)} label={scale.rating} onChange={(e, checked) => toggleCategoryFilter(cat.key, scale.rating, checked)} />
                      })
                    }
                  </div>
                })
              }
            </div>
            <div hidden={showFilters} style={{flex: 1, overflowY: 'scroll'}}>
              {
                filteredData?.features.map((feature, i) => {
                  const { properties } = feature;
                  const { open, SITE, NAME="",  } = properties;
                  if(searchFilter && !NAME.toLowerCase().includes(searchFilter.toLowerCase())) return null;
                  return <div key={i}>
                    <div style={{display: 'flex', alignItems: 'center'}} key={SITE}>
                      <IconButton iconProps={{iconName: open ? "ChevronDownMed" : "ChevronRightMed"}} onClick={() => changePropertyById(SITE, {open: !open})} />
                      <ActionButton 
                        onMouseEnter={() => setHoveredSite(SITE)}
                        onMouseLeave={() => setHoveredSite(null)}
                        styles={{root: {textAlign: 'left'}}} 
                        onClick={() => zoomToFeature(feature)}>{NAME}</ActionButton>
                    </div>
                    {
                      open && <div>
                        <Properties CATEGORIES={CATEGORIES} {...properties} />
                      </div>
                    }
                  </div>
                })
              }
            </div>
          </div>
        </>
      }} />


      <Route path={`${props.match.path}/table`} render={(p) => {
        return <div style={{height: '50vh', display: 'flex', boxShadow: Depths.depth64, overflowY: 'scroll', background: '#fff', zIndex: 9999}}>
        <table style={{borderCollapse: 'collapse', width: '100%', height: 'max-content'}}>
          <thead>
            <tr style={{position: "sticky", top: 0, background: '#fff', boxShadow: Depths.depth16, zIndex: 99999}}>
              <td style={{textAlign: "left"}}>
                Wetland
                <IconButton iconProps={{iconName: "Sort"}} onClick={() => sortData("NAME", false, true)} />
              </td>
              {
                CATEGORIES.map(cat => {
                  return <td style={{textAlign: "left", background: selectedFormat === cat.key ? myTheme.palette.themeLight : '#fff'}} key={cat.key}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <ActionButton onClick={() => setSelectedFormat(cat.key)} styles={{root: {flex: 1}}}>{cat.name}</ActionButton>
                      {
                        cat.type === 'input' ? (
                          <IconButton iconProps={{iconName: "Sort"}} onClick={() => sortData(cat.key, false, false, cat.direction)} />
                        ) : (
                          <>
                            <IconButton iconProps={{iconName: "Sort"}} onClick={() => sortData(cat.key, true)} />
                            <IconButton
                              iconProps={{iconName: filters[cat.key].length ? "FilterSolid" : "Filter"}}
                              menuProps={{items: cat.scale.map(scale => ({key: scale.key, text: scale.rating, onRender: (props) => {
                                return <Checkbox 
                                  checked={filters[cat.key].includes(scale.rating)}
                                  onChange={(e, checked) => toggleCategoryFilter(cat.key, scale.rating, checked)}
                                  label={props.text} 
                                  styles={{root: {margin: 8}}} />
                              }}))}} />
                          </>
                        )
                      }
                    </div>
                  </td>
                })
              }
              <td style={{textAlign: 'left', background: selectedFormat === 'utility' ? myTheme.palette.themeLight : '#fff'}} onClick={() => setSelectedFormat('utility')}>
                <div style={{display: 'flex', height: '100%', alignItems: "center"}}>
                  <ActionButton>Weighted Utility</ActionButton>
                  <IconButton iconProps={{iconName: "Sort"}} onClick={() => sortData('utility_index')} />
                </div>
              </td>
            </tr>
          </thead>
          <tbody style={{fontSize: '90%'}}>
            {
              filteredData?.features.map((feature, i) => {
                const { NAME, SITE } = feature.properties;
                return <tr key={i}>
                  <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`}}><span onMouseLeave={() => setHoveredSite(null)} onMouseEnter={() => setHoveredSite(SITE)} onClick={() => zoomToFeature(feature)}>{NAME}</span></td>
                  {
                    CATEGORIES.map(cat => {
                      let cellColor = getTheme().palette.neutralLight;
                      let catValue = feature.properties[cat.key];

                      if(cat.type === 'input'){
                        let norm = normalize(parseFloat(catValue), parseFloat(cat.max), parseFloat(cat.min), cat.direction);
                        cellColor = interpolateRdYlGn(norm);
                      } else {
                        let scaleMatch = cat.scale.find(el => el.rating === catValue);
                        if(scaleMatch){
                          cellColor = scaleMatch.color;
                        }
                      }
  

                      if(cat.type === 'input'){
                        if(catValue === null || catValue === undefined){
                          catValue = '';
                        }
                        return <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`}} key={cat.key}>
                          <TextField 
                            borderless
                            styles={{root: {flex: 1, borderLeft: `8px solid ${cellColor}`}}}
                            onChange={(e, val) => changeFeatureInputValue(feature, val, cat.key)}
                            value={catValue} />
                        </td>
                      }

                      return <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`}} key={cat.key}>
                        <Dropdown 
                          styles={{root: {flex: 1}, title: {border: 'none', borderLeft: `8px solid ${cellColor} !important`, borderRadius: 0, background: '#fff'}}}
                          onChange={(e, o) => changeFeatureCategoyValue(feature, o, cat.key)}
                          selectedKey={catValue} 
                          options={cat.scale.map(scale => ({key: scale.rating, text: scale.rating}))} />
                      </td>
                    })
                  }
                  <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`, borderLeft: `8px solid ${interpolateRdYlGn(feature.properties.utility_index)}`}}>
                    <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                      <span>{Math.round(feature.properties['utility_index'] * 100) / 100}</span>
                    </div>
                    
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
      }} />


      <Route path={`${props.match.path}/charts`} render={(p) => {
        return <div style={{width: 600, display: 'flex', flexDirection: 'row-reverse', boxShadow: Depths.depth64, background: '#fff', position: 'relative', boxShadow: Depths.depth64, zIndex: 998, paddingTop: 32}}>
        <div style={{flex: 1, overflowY: 'scroll', background: getTheme().palette.neutralLighter, boxShadow: Depths.depth16}}>
          <div style={{padding: 16, position: 'sticky', top: 0, background: '#fff', marginBottom: 12, borderBottom: `2px solid ${getTheme().palette.neutralSecondary}`, zIndex: 2}}>
            <h2>Weights</h2>
            <table style={{width: '100%', borderCollapse: 'collapse'}}>
              <thead>
                <tr>
                  <td>Category</td>
                  <td></td>
                  <td>Value</td>
                  <td>Weight</td>
                </tr>
              </thead>
              <tbody>
                {
                  recursiveRenderer(weighting)
                }
              </tbody>
            </table>
            <div style={{alignItems: 'flex-end', marginTop: 16, display: 'flex', gap: 8}}>
              <TextField label='Save Weights' styles={{root: {flex: 1}}} value={name} onChange={(e, val) => setName(val)} placeholder='Your Name or Organization' />
              <PrimaryButton text="Save Weights" disabled={!name} onClick={saveWeights} />
            </div>
          </div>
          <div style={{display: "flex", justifyContent: 'flex-end'}}>
            <ActionButton text="Sort" iconProps={{iconName: "Sort"}} onClick={() => sortData('utility_index')} />
          </div>
          <table style={{width: '100%'}}>
            <tbody>
              {
                filteredData?.features?.map((feature, i) => {
                  const { properties } = feature;
                  return <tr key={i}>
                    <td style={{width: '400px'}} onMouseEnter={() => setHoveredSite(properties.SITE)} onMouseLeave={() => setHoveredSite(null)} onClick={() => zoomToFeature(feature)}>
                      <ActionButton styles={{root: {paddingTop: 4, paddingBottom: 4, height: 'auto'}}}>{properties.NAME}</ActionButton>
                      
                    </td>
                    <td>
                      <div style={{display: 'flex', width: '100%', border: `1px solid`, height: '18px', boxShadow: Depths.depth8}}>
                          {
                            properties.y?.map((utility, x) => {
                              return <div key={x} style={{width: `${utility * 100}%`, background: properties.colors[x], height: 18, boxShadow: Depths.depth8}}>
                              </div>
                            })
                          }
                      </div>
                    </td>
                  </tr>
                })
              }
            </tbody>
          </table>
        </div>
      </div>
      }} />

      <Route path={`${props.match.path}/settings`} render={(p) => {
        return <Settings {...p} data={data} categories={CATEGORIES} setCategories={setCategories} saveData={save}/>
      }} />

    </Switch>
    
    <div style={{flex: 1, position: 'relative'}}>

      <Map 
        interactive
        mapStyle={basemap}
        onClick={onClickMap}
        initialViewState={viewState}
        interactiveLayerIds={["data"]}
        onMouseMove={onMouseMove}
        mapboxAccessToken={mapboxAccessToken}
        ref={mapRef}>
        <Source type="geojson"  data={filteredData}>
          <Layer  {...layerProps} />
          <Layer  {...lineProps} />
        </Source>
        {popup}
      </Map>

      { hoverSiteName }
    </div>
  </div>
}

const InterpolatedScale = (props) => {
  const {showHoveredValue=true, hoveredValue=0.5} = props;
  return <div style={{display: 'flex', borderRadius: 4, overflow: 'hidden', position: 'relative'}}>
    {
      showHoveredValue && <div style={{position: 'absolute', left: `calc(${hoveredValue * 100}% - 2px)`, width: '2px', height: '150%', background: '#000', top: '-5px', zIndex: 999}}></div>
    }
    {
      INTERPOLATED_COLOR_ARRAY.map((col, i) => {
        return <div key={i} style={{flex: 1, height: 32, background: col}}></div>
      })
    }
  </div>
}

const Properties = (props) => {
  return <div>
    <Label>Summary Metrics</Label>
    <table style={{width: '100%', borderCollapse: 'collapse', marginBottom: 16}}>
      <tbody>
        {
          props.CATEGORIES.map(cat => {

            let catValue = props[cat.key]; // get the value
            let cellColor = getTheme().palette.neutralLight; // get default cell color

            if(cat.type === 'input'){
              let norm = normalize(parseFloat(catValue), parseFloat(cat.max), parseFloat(cat.min), cat.direction);
              cellColor = interpolateRdYlGn(norm);
            } else {
              // if using a constructed scale
              let scaleMatch = cat.scale.find(el => el.rating === catValue);
  
              if(scaleMatch){
                cellColor = scaleMatch.color;
              }
            }

            if(catValue === '' || catValue === null){
              catValue = 'NA';
            }

            return <tr key={cat.key}>
              <td style={{padding: 4, borderBottom: `1px solid ${getTheme().palette.neutralLight}`, borderTop: `1px solid ${getTheme().palette.neutralLight}`, borderColor: getTheme().palette.neutralLight}}>{cat.name}</td>
              <td style={{padding: 4, borderBottom: `1px solid ${getTheme().palette.neutralLight}`, borderTop: `1px solid ${getTheme().palette.neutralLight}`, borderColor: getTheme().palette.neutralLight}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{height: 16, width: 16, background: cellColor, boxShadow: Depths.depth8, marginRight: 8, border: `1px solid ${getTheme().palette.neutralTertiary}`}}></div>
                  <span>{catValue}</span>
                </div>
              </td>
            </tr>
          })
        }
      </tbody>
    </table>
    <Label>Weighted Utility Index</Label>
    <InterpolatedScale hoveredValue={props.utility_index} showHoveredValue />
    <div style={{textAlign: 'center', marginTop: 4, marginBottom: 0}}>{Math.round(props.utility_index * 100) / 100}</div>
  </div>

}