import axios from 'axios';
import { useParams } from 'react-router-dom';
import React from 'react';
import { caluclateFeatureUtilityScore, flatten, getWeightedPercentageFromRaw } from './utilities';
import { interpolateRdYlGn } from 'd3-scale-chromatic';
import { groupBy, round } from 'lodash';
import { DefaultButton, IconButton, getTheme, Depths } from '@fluentui/react';

function downloadCSVfromArrays(data, filename = 'data.csv') {
  // Convert array of arrays to CSV string
  const arrayToCSV = (arr) => {
      // Map each row array to a CSV string, escaping commas and quotes
      return arr.map(row => row.map(field => 
          `"${field.toString().replace(/"/g, '""')}"` // Handle quotes within fields
      ).join(',')).join('\r\n');
  };

  // Convert the data to CSV format
  const csvData = arrayToCSV(data);

  // Create a Blob from the CSV data
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

  // Create a link element, use it to download the blob, and remove it
  const link = document.createElement('a');
  if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }
}

function UserWeights(props){

  // console.log(props);

  const [loading, setLoading] = React.useState(true);
  const [userWeights, setUserWeights] = React.useState([]);
  const [showAverage, setShowAverage] = React.useState(true);

  const { project_key } = useParams();

  const tableData = React.useMemo(() => {
    if(!props.data || !userWeights) return [];
    return userWeights.map(user => {
      // console.log(user);
      let weightedPercent = getWeightedPercentageFromRaw(user.data.weights);
      const features = caluclateFeatureUtilityScore(props.data.features, weightedPercent, user.data.weights);
      return {
        ...user,
        features
      }
    })
  }, [userWeights, props.data]);

  const weighting = React.useMemo(() => {
    if(!props.data) return [];
    return props.data.weighting || [];
  }, [props.data]);

  const fetchData = () => {
    axios.get(`/api/project/${project_key}/weights`)
    .then(res => {
      // console.log(res);
      if(res.data.success){
        setUserWeights(res.data.weights.map(d => ({...d, visible: true})));
      }
      setLoading(false);
    })
    .catch(err => {
      // console.log(err);
      setLoading(false);
    })
  }

  const deleteData = () => {
    const confirm = window.prompt("This will permanently delete the existing weights. Please enter the project pin to confirm.");
    if(confirm === 'terraviz'){
      axios.delete(`/api/project/${project_key}/weights`)
        .then(res => {
          setUserWeights([]);
        })
        .catch(err => {
  
        })
    } else {
      window.alert("Incorrect pin");
    }
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  // console.log(tableData);

  const hideData = (id) => {
    setUserWeights(prev => {
      return prev.map(el => {
        if(el.id === id){
          return {
            ...el,
            visible: false
          }
        }
        return el
      })
    })
  };

  const downloadCsv = () => {
    let arr = [];
    let head = ['Wetland'];
    {
      tableData.forEach(({id, data, visible}) => visible && head.push(data.name));
    }
    head.push("Group Average");
    arr.push(head);
    {
      props.data?.features.forEach((feature, i) => {
        let row = [];
        let allUserUtility = [];
        tableData.forEach(({features, visible}) => {
          if(!visible) return;
          let match = features.find(el => el.properties.SITE === feature.properties.SITE);
          allUserUtility.push(match.properties.utility_index);
        });
        const sum = allUserUtility.reduce((prev, curr) => prev + curr, 0);
        let avg = sum / allUserUtility.length;

        row.push(feature.properties.NAME);
        {
          tableData.forEach((each, i) => {
            if(!each.visible) return;
            let matchingFeat = each?.features.find(el => el.properties.SITE === feature.properties.SITE);
            if(!matchingFeat) row.push("NA");
            row.push(round(matchingFeat.properties.utility_index, 2));
          })
        }
        row.push(round(avg, 2));
        arr.push(row);
      })
    }

    downloadCSVfromArrays(arr, "Terraviz Weights.csv");
  }

  if(!tableData.length || !props.data) return <div style={{width: "100%", height: '100%', padding: 32}}>
    <h1>No data collected</h1>
  </div>;

  return <div style={{paddingTop: 32, width: '100%', paddingLeft: 16, paddingRight: 16}}>
    <h1>
      User Weights
    </h1>
    {
      loading && <p>Loading weights...</p>
    }
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <h2 style={{flex: 1}}>
        Gross Utility
      </h2>
      <div style={{display: 'flex', gap: 8}}>
        <DefaultButton text="Download Data" iconProps={{iconName: "Download"}} onClick={downloadCsv} />
        <DefaultButton text="Clear Data" iconProps={{iconName: "Delete"}} onClick={deleteData} />
      </div>
    </div>
    <table style={{borderCollapse: 'collapse', background: '#fff', boxShadow: Depths.depth16}}>
      <thead>
        <tr>
          <td style={{borderBottom: `2px solid ${getTheme().palette.neutralLight}`}}>Wetland</td>
          {
            tableData.map(({id, data, visible}) => visible && <td key={data.name} style={{width: 80, borderBottom: `2px solid ${getTheme().palette.neutralLight}`, textAlign: 'center'}}>
              <IconButton iconProps={{iconName: "Clear"}} onClick={() => hideData(id)} />
              <div>{data.name}</div>
            </td>)
          }
          <td style={{width: 80, borderBottom: `2px solid ${getTheme().palette.neutralLight},`, textAlign: 'center'}}>
            {/* <IconButton iconProps={{iconName: "Clear"}} onClick={() => setShowAverage(false)} /> */}
            <div>
              Group Average
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        {
          props.data?.features.map((feature, i) => {
            let allUserUtility = [];
            tableData.forEach(({features, visible}) => {
              if(!visible) return;
              let match = features.find(el => el.properties.SITE === feature.properties.SITE);
              allUserUtility.push(match.properties.utility_index);
            });
            const sum = allUserUtility.reduce((prev, curr) => prev + curr, 0);
            let avg = sum / allUserUtility.length;

            return <tr key={i}>
              <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`}}>{feature.properties.NAME}</td>
              {
                tableData.map((each, i) => {
                  if(!each.visible) return null;
                  let matchingFeat = each?.features.find(el => el.properties.SITE === feature.properties.SITE);
                  if(!matchingFeat) return <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`}} key={i}>NA</td>
                  return <td key={i} style={{background: interpolateRdYlGn(matchingFeat.properties.utility_index), color: matchingFeat.properties.utility_index < 0.15 || matchingFeat.properties.utility_index > 0.85 ? '#fff' : 'inherit', borderBottom: `1px solid ${getTheme().palette.neutralLight}`}}>
                    {round(matchingFeat.properties.utility_index, 2)}
                  </td>
                })
              }
              <td style={{background: interpolateRdYlGn(avg), color: avg < 0.15 || avg > 0.85 ? '#fff' : 'inherit', borderBottom: `1px solid ${getTheme().palette.neutralLight}`}}>{round(avg, 2)}</td>
            </tr>
          })
        }
      </tbody>
    </table>
    <h2>Weights</h2>
    <table>
      <thead>
        <tr>
          <td>Category</td>
          {
            tableData.map((user, i) => {
              return <td key={i}>{user.data.name}</td>
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          flatten(weighting).map(cat => {
            return <tr key={cat.id}>
              <td style={{paddingLeft: cat.parent !== 'root' ? 24 : 8}}>{cat.name}</td>
              {
                tableData.map((user, i) => {
                  const weightedPercent = getWeightedPercentageFromRaw(user.data.weights);
                  const weight = flatten(user.data.weights).find(el => el.id === cat.id)?.weight;
                  const percent = round(weight / weightedPercent[cat.parent], 2);
                  // console.log({weights: weightedPercent, user: user.data.weights});
                  return <td key={i}>{percent}</td>
                })
              }
            </tr>
          })
        }
      </tbody>
    </table>
  </div>
}

export default UserWeights