import axios from 'axios';
import { useParams } from 'react-router-dom';
import React from 'react';
import { Depths, getTheme } from '@fluentui/react';

function SurveyResponses(props){

  const [loading, setLoading] = React.useState(true);
  const [responses, setResponses] = React.useState([]);

  const { project_key } = useParams();

  const fetchData = () => {
    axios.get(`/api/project/${project_key}/survey-responses`)
    .then(res => {
      console.log(res);
      if(res.data.success){
        setResponses(res.data.surveys);
      }
      setLoading(false);
    })
    .catch(err => {
      // console.log(err);
      setLoading(false);
    })
  }

  React.useEffect(() => {
    fetchData();
  }, []);
  
  if(!props.data) return;



  return <div style={{paddingTop: 48, width: '100%', padding: 16}}>
    <div style={{width: 1000}}>
      <h1>Effort Mapping Results</h1>
      {
        responses.map((response, i) => {
          return <div key={i} style={{padding: 16, background: '#fff', boxShadow: Depths.depth8, marginBottom: 8 }}>
            <ResponseTable {...response} features={props.data.features} />

          </div>
        })
      }
    </div>
  </div>
}

export const ResponseTable = (props) => {

  
  const resolveAreasBySiteIds = (areas=[]) => {
    let list = [];
    props.features.forEach(feat => {
      if(areas.includes(feat.properties.SITE)){
        list.push(feat.properties.NAME);
      }
    });
    return list;
  }

  return <table style={{borderCollapse: 'collapse'}}>
  <tbody>
    <tr>
      <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`}}>Wetland(s): </td>
      <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`}}>{resolveAreasBySiteIds(props.data.selectedAreas).join(', ')}</td>
    </tr>
    <tr>
      <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`}}>Timing: </td>
      <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`}}>{props.data.when.join(', ')}</td>
    </tr>
    <tr>
      <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`}}>Efforts: </td>
      <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`}}>{props.data.what.join(', ')}</td>
    </tr>
    <tr>
      <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`}}>Organization / Name: </td>
      <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`}}>{props.data.who}</td>
    </tr>
    <tr>
      <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`}}>Comments: </td>
      <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`}}>{props.data.comment}</td>
    </tr>
  </tbody>

</table>
}

export default SurveyResponses;
