import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Logo } from '../Logo';
import { Depths, PrimaryButton, ProgressIndicator, Stack, TextField, getTheme } from '@fluentui/react';
import { jwtDecode } from "jwt-decode";
import { Link } from 'react-router-dom';

const EditProfileForm = () => {
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: ''
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        console.log(decodedToken);
        setUser(decodedToken);
        setFormData({
          email: decodedToken.email,
          firstName: decodedToken.firstName,
          lastName: decodedToken.lastName,
          password: '',
          confirmPassword: ''
        });
      } catch (error) {
        console.error('Error decoding token:', error.message);
      }
    }
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post('/api/auth/update', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setMessage(response.data.message);
    } catch (error) {
      console.error('Error updating profile:', error.message);
      setMessage('Failed to update profile');
    }
  };

  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
      <div style={{width: 400, boxShadow: Depths.depth64, padding: 32, background: '#fff'}}>
        {user ? (<>
          <div style={{display: "flex", alignItems: 'center', gap: 16}}>
            <Logo />
            <h2>Edit Profile</h2>
          </div>
          <form onSubmit={handleSubmit}>
            <Stack tokens={{childrenGap: 12}}>
            <TextField label='First Name' type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
            <TextField label='Last Name' type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
            <TextField label='Email' type="email" name="email" value={formData.email} onChange={handleChange} required />
            <TextField label='Password' type="password" name="password" value={formData.password} onChange={handleChange} required />
            <TextField label='Confirm Password' type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required />
            <PrimaryButton onClick={handleSubmit}>Save Changes</PrimaryButton>
          </Stack>
            {message && <p>{message}</p>}
          </form>
        </>
        ) : (<>
          <div style={{display: "flex", alignItems: 'center', gap: 16}}>
            <Logo />
            <h2>Profile Not Found</h2>
          </div>
          <p>Please <Link to="/sign-in">sign in</Link> to your account.</p>
        </>
          
        )}
      </div>
    </div>
  );
};

export default EditProfileForm;