import React from 'react';
import { Route, Switch, NavLink, BrowserRouter } from 'react-router-dom';

import Terraviz from './Terraviz';
import CreateUserForm from './User/CreateUserForm';
import SignInForm from './User/SignInForm';
import EditProfileForm from './User/EditUserForm';
import Landing from './Landing';
import { ActionButton, Depths } from '@fluentui/react';

const App = (props) => {
  return (
    <BrowserRouter>
      <nav style={{display: 'none', position: 'fixed', top: 0, width: '100%', background: '#fff', boxShadow: Depths.depth16}}>
        <NavLink to="/sign-up">
          <ActionButton>
            Create Account
          </ActionButton>
        </NavLink>
        <NavLink to="/sign-in">
          <ActionButton>
            Sign In
          </ActionButton>
        </NavLink>
        <NavLink to="/edit-profile">
          <ActionButton>
            Edit Profile
          </ActionButton>
        </NavLink>
        {/* <NavLink to="/project/:project_key">
          <ActionButton>
            St. Marys Project
          </ActionButton>
        </NavLink> */}
      </nav>
      <Switch>
        <Route path="/" exact component={ Landing }/>
        <Route path="/sign-up" component={ CreateUserForm }/>
        <Route path="/sign-in" component={ SignInForm }/>
        <Route path="/edit-profile" component={ EditProfileForm }/>
        <Route path="/project/:project_key" component={ Terraviz }/>
      </Switch>
    </BrowserRouter>
  );
};

export default App;