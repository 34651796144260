import React, { useState } from 'react';
import axios from 'axios';
import { ActionButton, Depths, PrimaryButton, ProgressIndicator, Stack, TextField, getTheme } from '@fluentui/react';
import { Link } from 'react-router-dom';
import { Logo } from '../Logo';

const CreateUserForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    setErrorMessage('');
    setSuccessMessage('');
    setLoading(true);

    try {
      const response = await axios.post('/api/auth/createUser', formData);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      setSuccessMessage(response.data.message);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
      });
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
      <div style={{width: 400, boxShadow: Depths.depth64, padding: 32, background: '#fff'}}>
        <div style={{display: "flex", alignItems: 'center', gap: 16}}>
          <Logo />
          <h2>Create Account</h2>
        </div>
        <div style={{margin: '16px 0px 16px 0px'}}>
          {errorMessage && <p style={{ color: getTheme().palette.redDark }}>{errorMessage}</p>}
          {successMessage && <p style={{ color: getTheme().palette.green }}>{successMessage}</p>}
          <ProgressIndicator percentComplete={loading ? undefined : 0} />
        </div>
        <form onSubmit={handleSubmit}>
          <Stack tokens={{childrenGap: 12}}>
            <TextField label='First Name' type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
            <TextField label='Last Name' type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
            <TextField label='Email' type="email" name="email" value={formData.email} onChange={handleChange} required />
            <TextField label='Password' type="password" name="password" value={formData.password} onChange={handleChange} required />
            <TextField label='Confirm Password' type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required />
            <PrimaryButton onClick={handleSubmit}>Create Account</PrimaryButton>
            <Link to="sign-in" style={{width: '100%'}}>
              <ActionButton text="Have an account? Sign in" />
            </Link>
          </Stack>
        </form>

      </div>
    </div>
  );
};

export default CreateUserForm;