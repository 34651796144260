import React, { useState } from 'react';
import axios from 'axios';
import { Depths, PrimaryButton, ProgressIndicator, Stack, TextField, getTheme } from '@fluentui/react';
import { Logo } from '../Logo';

const SignInForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    
    if(loading) return;
    
    if (!formData.email || !formData.password) {
      setErrorMessage('Enter an email and password');
      return;
    }

    setErrorMessage('');
    setSuccessMessage('');
    setLoading(true);

    try {
      const response = await axios.post('/api/auth/signin', formData);
      console.log(response);
      localStorage.setItem('token', response.data.token);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 2000);
      // setSuccessMessage(response.data.message);
      setLoading(false);
      setFormData({
        email: '',
        password: '',
      });
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
      <div style={{width: 400, boxShadow: Depths.depth64, padding: 32, background: '#fff'}}>
        <div style={{display: "flex", alignItems: 'center', gap: 16}}>
          <Logo />
          <h2>Sign In</h2>
        </div>
        <div style={{margin: '16px 0px 16px 0px'}}>
          {errorMessage && <p style={{ color: getTheme().palette.redDark }}>{errorMessage}</p>}
          {successMessage && <p style={{ color: getTheme().palette.green }}>{successMessage}</p>}
          <ProgressIndicator percentComplete={loading ? undefined : 0} />
        </div>
        <form onSubmit={handleSubmit}>
          <Stack tokens={{childrenGap: 12}}>
            <TextField label='Email' type="email" name="email" value={formData.email} onChange={handleChange} required />
            <TextField label='Password' type="password" name="password" value={formData.password} onChange={handleChange} required />
            <PrimaryButton onClick={handleSubmit}>Sign In</PrimaryButton>
          </Stack>
        </form>

      </div>
    </div>
  );
};

export default SignInForm;